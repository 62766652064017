import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Caption } from '../../styles/shared'

export const ReferenceLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['removeMargin', 'isCaption'].includes(prop),
})<{ removeMargin: boolean; isCaption: boolean }>(
  ({ removeMargin, isCaption }) => [
    !removeMargin && ReferenceLinkMargin,

    isCaption && Caption,
  ],
)

const ReferenceLinkMargin = () => css`
  margin-right: 4px;
`
