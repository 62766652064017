import React, { useState } from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { isNil } from 'lodash'
import { getSchemaNameFromRef } from '../../utils/ref'
import Button from '../button'
import ExpandIcon from '../expand-icon'
import Enum from '../enum'
import {
  PropertyItem,
  PropertyIdentifier,
  PropertyName,
  FlexLine,
  PropertyCaption,
  PropertyTags,
  PropertyDescription,
  Value,
} from '../properties-table/styled'
import SchemaReferenceLink from '../schema-reference-link'
import SchemaReferenceOptions from '../schema-reference-options'
import Tag from '../tag'
import { SchemaProp } from './types'

const getCaptionForArray = (property: SchemaProp) => {
  if (!property?.items) {
    return null
  }

  if (property.items._ref) {
    return getSchemaNameFromRef(property.items._ref)
  } else if (property.items.type) {
    const propertyType = property.items.type
    const isEnum = property.items.enum?.length
    const enumCaption = isEnum ? '/enum' : ''

    return `${propertyType}${enumCaption}`
  }
}

type Props = {
  property: SchemaProp
  required: SchemaProp['required']
  children: JSX.Element
}

export const SchemaPropertyItem: React.FC<Props> = (props) => {
  const { property, required, children } = props

  const {
    name,
    type,
    format,
    description,
    readOnly,
    writeOnly,
    nullable,
    minimum,
    maximum,
    maxLength,
    minLength,
    deprecated,
    _default,
  } = property

  const isRequired =
    required && property.name && required.includes(property.name)

  const isDeprecated = deprecated || false

  //Caption
  const captionForArray = getCaptionForArray(property)
  const captionBetweenBrackets = captionForArray || format

  // Extracting if the should show a ReferenceLink on the description
  const _ref = property._ref || property.items?._ref
  const refName = getSchemaNameFromRef(_ref)

  const arrayIsSameAsRef = refName && refName === captionBetweenBrackets

  const _enum = property.enum || property.items?.enum

  const anyOf = property.items?.anyOf
  const oneOf = property.oneOf || property.items?.oneOf

  const pattern = property.pattern || property?.items?.pattern

  const hasNestedProps =
    !!property?.properties?.length ||
    !!property.items?.properties?.length ||
    !!property.additionalProperties

  const [expanded, setExpanded] = useState(!_ref)

  return (
    <>
      <PropertyItem key={name} isDeprecated={isDeprecated}>
        <PropertyIdentifier>
          <PropertyName>{name}</PropertyName>
          <FlexLine>
            <PropertyCaption>{type}</PropertyCaption>
            {captionBetweenBrackets && _ref && arrayIsSameAsRef ? (
              <PropertyCaption>
                (<SchemaReferenceLink schemaId={_ref} removeMargin isCaption />)
              </PropertyCaption>
            ) : captionBetweenBrackets ? (
              <PropertyCaption>({captionBetweenBrackets})</PropertyCaption>
            ) : (
              <></>
            )}
          </FlexLine>
          {/* Reference Link */}
          {_ref && !arrayIsSameAsRef && name && (
            <FlexLine>
              <SchemaReferenceLink schemaId={_ref} isCaption />
            </FlexLine>
          )}
          {nullable && (
            <FlexLine>
              <PropertyCaption>nullable</PropertyCaption>
            </FlexLine>
          )}
          {readOnly && (
            <PropertyTags>
              <Tag variant="gray">READ ONLY</Tag>
            </PropertyTags>
          )}
          {writeOnly && (
            <PropertyTags>
              <Tag variant="gray">WRITE ONLY</Tag>
            </PropertyTags>
          )}
          {isRequired && (
            <PropertyTags>
              <Tag variant="red">REQUIRED</Tag>
            </PropertyTags>
          )}
          {isDeprecated && (
            <PropertyTags>
              <Tag variant="strong-red">DEPRECATED</Tag>
            </PropertyTags>
          )}
        </PropertyIdentifier>
        <PropertyDescription>
          {/* Description */}
          <Value>
            {description && <MDXRenderer>{description}</MDXRenderer>}
          </Value>
          {/* Attributes */}
          {!isNil(_default) && (
            <PropertyCaption>Default: {_default}</PropertyCaption>
          )}
          {!isNil(pattern) && (
            <PropertyCaption>Pattern: {pattern}</PropertyCaption>
          )}
          {/* TODO: don't need to render example field here */}
          {/* {!isNil(example) && (
            <PropertyCaption>Example: {example}</PropertyCaption>
          )} */}
          {!isNil(minimum) && (
            <PropertyCaption>Minimum: {minimum}</PropertyCaption>
          )}
          {!isNil(maximum) && (
            <PropertyCaption>Maximum: {maximum}</PropertyCaption>
          )}
          {!isNil(maxLength) && (
            <PropertyCaption>Max Length: {maxLength}</PropertyCaption>
          )}
          {!isNil(minLength) && (
            <PropertyCaption>Min Length: {minLength}</PropertyCaption>
          )}
          {/* anyOf, oneOf */}
          {anyOf && <SchemaReferenceOptions title="Any of" refs={anyOf} />}
          {oneOf && <SchemaReferenceOptions title="One of" refs={oneOf} />}
          {/* Enum */}
          {_enum && <Enum enum={_enum} />}
          {hasNestedProps && (
            <Button
              variant="transparent"
              size="small"
              onClick={() => setExpanded(!expanded)}
            >
              Child properties <ExpandIcon isExpanded={expanded} />
            </Button>
          )}
        </PropertyDescription>
      </PropertyItem>
      {hasNestedProps && expanded && children}
    </>
  )
}
