import { graphql } from 'gatsby'
import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childOpenApiSpecRequestBody,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_requestBody,
} from '../../templates/openapi/__generated__/OpenApiResponse'
import Tag from '../tag'
import {
  Header,
  PropertyIdentifier,
  PropertyItem,
  PropertyName,
  PropertyDescription,
  Value,
  PropertyTags,
} from '../properties-table/styled'
import SchemaReferenceLink from '../schema-reference-link'
import { Container } from './styled'

type Props = {
  requestBody:
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_requestBody
    | null
    | undefined

  refRequestBody: OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childOpenApiSpecRequestBody | null
}

const RequestBodyTable: React.FC<Props> = (props: Props) => {
  const requestBody = props.requestBody?.content?.application_json?.schema
    ? props.requestBody
    : props.refRequestBody

  const schema = requestBody?.content?.application_json?.schema

  if (!requestBody || !schema) {
    return null
  }

  // @ts-ignore
  const schemaType = schema?.type || 'object'

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const ref = schema._ref || schema?.items?._ref
  const description = requestBody?.description

  return (
    <Container>
      <Header>REQUEST BODY</Header>
      <PropertyItem>
        <PropertyIdentifier>
          <PropertyName>{schemaType}</PropertyName>
          {requestBody.required && (
            <PropertyTags>
              <Tag variant="red">REQUIRED</Tag>
            </PropertyTags>
          )}
        </PropertyIdentifier>
        <PropertyDescription>
          <Value>
            {description && <MDXRenderer>{description}</MDXRenderer>}
          </Value>
          {ref && <SchemaReferenceLink schemaId={ref} />}
        </PropertyDescription>
      </PropertyItem>
    </Container>
  )
}

export default RequestBodyTable

export const query = graphql`
  fragment fragment_ref_requestBody on openApiSpecPath {
    childOpenApiSpecRequestBody {
      description
      required
      content {
        application_json {
          example
          schema {
            _ref
          }
        }
      }
    }
  }

  fragment fragment_requestBody on openApiSpecPath {
    ...fragment_ref_requestBody
    requestBody {
      description
      required
      content {
        application_json {
          example
          schema {
            type
            _ref
            items {
              _ref
            }
          }
        }
      }
    }
  }
`
