import React from 'react'
import { graphql, PageProps } from 'gatsby'
import SEO from '../../components/seo'
import OpenApiTag from '../../components/openapi-tag'
import OpenApiTagObjects from '../../components/openapi-tag-objects'
import { OpenApiResponse } from './__generated__/OpenApiResponse'
import { Container } from './styled'

type Props = {
  body: string
  id: string
}

export const OpenApi: React.FC<PageProps<OpenApiResponse, Props>> = (props) => {
  const { allOpenApiSpecInfo } = props.data
  const tag = allOpenApiSpecInfo.edges[0]?.node

  if (!tag) return null

  return (
    <Container>
      <SEO title={tag.title || ''} />
      <OpenApiTag tag={tag} objects={tag.childrenOpenApiSpecSchema} />
      <OpenApiTagObjects objects={tag.childrenOpenApiSpecSchema} />
    </Container>
  )
}

export default OpenApi

export const query = graphql`
  query OpenApiResponse($tag: String) {
    allOpenApiSpecInfo(filter: { name: { eq: $tag } }) {
      edges {
        node {
          id
          name
          title
          version
          childrenOpenApiSpecPath {
            id
            name
            verb
            summary
            description
            exampleUrl
            deprecated
            ...fragment_requestBody
            ...fragment_parameters
            ...fragment_responses
            ...fragment_childrenOpenApiSpecResponse
          }
          ...fragment_childrenOpenApiSpecSchema
        }
      }
    }
  }
`
