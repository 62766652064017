import React from 'react'
import { getSchemaNameFromRef } from '../../utils/ref'
import {
  FlexLine,
  PropertyCaption,
  PropertyDescription,
  PropertyIdentifier,
  PropertyItem,
  PropertyName,
} from '../properties-table/styled'
import SchemaReferenceLink from '../schema-reference-link'
import { SchemaProp } from './types'
import SchemaProperties from './schema-properties'

type Props = {
  items: SchemaProp['items']
}

const SchemaItems: React.FC<Props> = ({ items }) => {
  if (!items) return null

  const { _ref } = items
  const _refName = getSchemaNameFromRef(_ref)
  const captionBetweenBrackets = _refName && `(${_refName})`
  const hasNestedSchema = !!items?.properties?.length

  if (hasNestedSchema) {
    return <SchemaProperties properties={items.properties} />
  }

  return (
    <PropertyItem key={_ref}>
      <PropertyIdentifier>
        <PropertyName>array</PropertyName>
        <FlexLine>
          <PropertyCaption>object</PropertyCaption>
          {captionBetweenBrackets && (
            <PropertyCaption>{captionBetweenBrackets}</PropertyCaption>
          )}
        </FlexLine>
      </PropertyIdentifier>
      <PropertyDescription>
        {_ref && <SchemaReferenceLink schemaId={_ref} />}
      </PropertyDescription>
    </PropertyItem>
  )
}

export default SchemaItems
