import React from 'react'
import { OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters_schema } from '../../templates/openapi/__generated__/OpenApiResponse'
import { getParameterType } from '../../utils/parameter'
import {
  PropertyItem,
  PropertyIdentifier,
  PropertyName,
  FlexLine,
  PropertyCaption,
  PropertyDescription,
  Value,
} from '../properties-table/styled'
import { SchemaProp } from './types'

type Props = {
  additionalProperties?: SchemaProp['additionalProperties']
}

const AdditionalProperties: React.FC<Props> = ({ additionalProperties }) => {
  if (!additionalProperties) {
    return null
  }

  const { type: parameterType, isArray } = getParameterType(
    additionalProperties as OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters_schema,
    null,
  )

  return (
    <PropertyItem>
      <PropertyIdentifier>
        <PropertyName>{'<*>'}</PropertyName>
        <FlexLine>
          <PropertyCaption>
            {isArray ? `array (${parameterType})` : parameterType}
          </PropertyCaption>
        </FlexLine>
      </PropertyIdentifier>
      <PropertyDescription>
        <Value>{additionalProperties.description}</Value>
      </PropertyDescription>
    </PropertyItem>
  )
}

export default AdditionalProperties
