import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_responses,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiSpecResponse,
} from '../../templates/openapi/__generated__/OpenApiResponse'
import RequestBody from '../request-body'
import ResponseTable from '../response-table'
import { LeftColumn, RightColumn } from '../../layouts/docs/styled'
import Code from '../code'
import Parameters from '../parameters'
import Anchor from '../anchor'
import ResponseBody from '../response-body'
import Verb from '../verb'
import Banner from '../banner'
import { Columns, Content, Endpoint, PathTitle, Url } from './styled'

type Props = {
  path: OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath | null
  index: number
  objects:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema | null)[]
    | null
}

const getSuccessResponses = (
  acc: string[],
  response:
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_responses
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiSpecResponse
    | null,
) => {
  if (response?.content) {
    const { statusCode, content } = response
    const successStatusCode =
      statusCode && ['200', '201', '20x'].includes(statusCode)
    const example = content?.application_json?.example

    if (successStatusCode && example) acc.push(example)
  }

  return acc
}

const PathOperation: React.FC<Props> = ({ path, index, objects }) => {
  if (!path || !path?.verb || !path?.name) return null

  const {
    verb,
    name,
    summary,
    description,
    requestBody,
    responses,
    parameters,
    exampleUrl,
    childrenOpenApiSpecResponse,
    childrenOpenApiParameter,
    childOpenApiSpecRequestBody,
  } = path

  const deprecated = path.deprecated || false

  const responseExamples = [
    ...(responses || []),
    ...(childrenOpenApiSpecResponse || []),
  ].reduce(getSuccessResponses, [])

  const requestExample =
    requestBody?.content?.application_json?.example ||
    childOpenApiSpecRequestBody?.content?.application_json?.example

  return (
    <Columns>
      <LeftColumn>
        {index !== 0 && <Anchor content={summary} />}
        <Content deprecated={deprecated}>
          <PathTitle>{summary}</PathTitle>
          <Endpoint>
            <Verb deprecated={deprecated}>{verb}</Verb> <Url>{name}</Url>
          </Endpoint>
        </Content>
        {deprecated && description && (
          <Banner type="error" title="Deprecated!">
            <MDXRenderer>{description}</MDXRenderer>
          </Banner>
        )}
        {!deprecated && description && <MDXRenderer>{description}</MDXRenderer>}
        <Content deprecated={deprecated}>
          <Parameters
            url={name}
            parameters={parameters}
            childrenOpenApiParameter={childrenOpenApiParameter}
            objects={objects}
          />
          <RequestBody
            requestBody={requestBody}
            refRequestBody={childOpenApiSpecRequestBody}
          />
          <ResponseBody
            operationResponses={responses}
            specResponses={childrenOpenApiSpecResponse}
          />
        </Content>
      </LeftColumn>
      <RightColumn>
        <Content deprecated={deprecated}>
          <Code
            className="language-json-request"
            headerContent={exampleUrl || name}
            headerTag={verb}
          >
            {requestExample}
          </Code>

          {responseExamples?.map((example, index) => (
            <Code
              key={index}
              className="language-json-request"
              headerContent="RESPONSE JSON"
            >
              {example}
            </Code>
          ))}

          <ResponseTable
            operationResponses={responses}
            specResponses={childrenOpenApiSpecResponse}
          />
        </Content>
      </RightColumn>
    </Columns>
  )
}

export default PathOperation
