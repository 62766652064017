import React from 'react'
import { getObjectAnchorId } from '../../utils/anchor'
import { getSchemaNameFromRef } from '../../utils/ref'
import { scrollToHash } from '../../utils/scroll-to-hash'
import { ReferenceLink } from './styled'

type Props = {
  schemaId: string | null
  isArray?: boolean | undefined
  removeMargin?: boolean
  isCaption?: boolean
}

const SchemaReferenceLink: React.FC<Props> = ({
  schemaId,
  isArray = false,
  removeMargin = false,
  isCaption = false,
}) => {
  if (!schemaId) return null

  const objectName = getSchemaNameFromRef(schemaId)

  const label = objectName || 'Object Reference'

  return (
    <ReferenceLink
      to={`#${getObjectAnchorId(schemaId).toLowerCase()}`}
      onClick={scrollToHash}
      removeMargin={removeMargin}
      isCaption={isCaption}
    >
      {isArray && `array (${label})`}
      {!isArray && `${label}`}
    </ReferenceLink>
  )
}

export default SchemaReferenceLink
