/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react'
import {
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiSpecResponse,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_responses,
} from '../../templates/openapi/__generated__/OpenApiResponse'
import { FlexLine, Header, PropertyCaption } from '../properties-table/styled'
import SchemaReferenceLink from '../schema-reference-link'
import { Container } from './styled'

type Props = {
  operationResponses:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_responses | null)[]
    | null
  specResponses:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiSpecResponse | null)[]
    | null
}

const ResponseBody = (props: Props) => {
  const operationResponses = props.operationResponses || []
  const specResponses = props.specResponses || []
  const responses = [...specResponses, ...operationResponses]

  if (!responses?.length) return null

  return (
    <Container>
      {responses.map((response) => {
        if (!response) return null

        const { statusCode } = response

        const _refDirect =
          response && '_ref' in response ? response._ref : undefined

        const schema = response.content?.application_json?.schema

        const _refSchema = schema?._ref

        const _refArray = schema?.items?._ref

        const _refItems =
          schema && 'properties' in schema
            ? schema?.properties?.items?.items?._ref
            : undefined

        const _ref = _refDirect || _refSchema || _refArray || _refItems

        const isSuccessCode =
          statusCode && ['200', '201', '20x'].includes(statusCode)

        if (isSuccessCode && _ref && response.content) {
          return (
            <React.Fragment key={_ref}>
              <Header>Returns</Header>
              <p>{response?.description}</p>
              <FlexLine>
                <SchemaReferenceLink schemaId={_ref} />
                {!!_refArray && <PropertyCaption>array</PropertyCaption>}
              </FlexLine>
            </React.Fragment>
          )
        }
      })}
    </Container>
  )
}

export default ResponseBody
