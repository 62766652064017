import styled, { css } from 'styled-components'
import { Subheader, Caption, Body01 } from '../../styles/shared'
import { ButtonSmall } from '../button/styled'

export const Container = styled.div`
  display: grid;

  & & {
    padding: 0 16px;
    background-color: ${({ theme }) => theme.palette.gray20};
    margin-bottom: 16px;

    /* make code blocks darker inside dark container*/
    code {
      background-color: ${({ theme }) => theme.palette.gray30};
      border-color: ${({ theme }) => theme.palette.gray40};
    }
  }

  & & & {
    background-color: ${({ theme }) => theme.palette.gray10};
  }

  & & & & {
    background-color: ${({ theme }) => theme.palette.gray10};
  }

  /* Remove padding for <Button>Child properties</Button> */
  ${ButtonSmall} {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    padding-right: 0;
  }
`

export const Header = styled.div`
  ${Subheader}
  color: ${({ theme }) => theme.palette.gray70};
  text-transform: uppercase;
  padding: 8px 2px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray30};
`

export const PropertyIdentifier = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-x: auto;
`

export const FlexLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: -6px;
`
export const PropertyName = styled.div`
  ${Body01}
  color: ${({ theme }) => theme.palette.gray80};
  font-weight: bold;
`

export const PropertyCaption = styled.div`
  ${Caption}
  color: ${({ theme }) => theme.palette.gray60};
  margin-right: 4px;
  margin-bottom: 4px;
`

export const PropertyType = styled.div`
  ${Caption}
  color: ${({ theme }) => theme.palette.gray80};
  margin-right: 4px;
`

export const PropertyTags = styled.div`
  margin-top: 8px;
`

export const PropertyDescription = styled.div`
  ${Body01}
  width: 100%;
  color: ${({ theme }) => theme.palette.gray80};
  word-break: break-word;

  p {
    margin-top: 0;
  }
`
export const Value = styled.div``

type PropertyItemProps = {
  isDeprecated?: boolean | undefined
}

export const PropertyItem = styled.div<PropertyItemProps>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 16px 0;
  grid-gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray30};
  overflow-x: auto;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }

  //If the field is deprecated, apply the styling accordingly
  ${({ isDeprecated, theme }) =>
    isDeprecated &&
    css`
      ${PropertyName} {
        color: ${theme.palette.gray60};
        text-decoration: line-through;
      }

      ${Value} {
        color: ${theme.palette.gray60};
      }
    `}

  &:last-child {
    border-bottom: none;
  }
`
