import styled from 'styled-components'
import { DisplayLarge } from '../../styles/shared'

// TODO: Confirm spacing
export const Columns = styled.div`
  display: grid;
  /* two equal columns */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 24px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.gray30};
  padding-bottom: 112px;
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

export const PathTitle = styled.h2`
  ${DisplayLarge}
`

export const Endpoint = styled.span`
  display: flex;
  align-items: center;
`

export const Url = styled.span`
  word-break: break-all;
  margin-left: 8px;
`

export const Content = styled.div<{ deprecated?: boolean }>`
  opacity: ${({ deprecated }) => (deprecated ? 0.7 : 1)};
`
