import React from 'react'
import { OneColumnTable } from '../table/styled'
import { Container } from './styled'

type Props = {
  enum: string[] | null
}

const Enum: React.FC<Props> = (props) => {
  if (!props.enum) return null
  return (
    <Container>
      <OneColumnTable>
        <thead>
          <tr>
            <th>VALUES</th>
          </tr>
        </thead>
        <tbody>
          {props.enum.map((e) => (
            <tr key={e}>
              <td>{e}</td>
            </tr>
          ))}
        </tbody>
      </OneColumnTable>
    </Container>
  )
}

export default Enum
