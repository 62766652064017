import React from 'react'
import styled from 'styled-components'
import {
  FlexLine,
  PropertyCaption,
  PropertyIdentifier,
  PropertyName,
} from '../properties-table/styled'
import SchemaReferenceLink from '../schema-reference-link'
import { SchemaProp } from './types'

type Props = {
  oneOf?: SchemaProp['oneOf']
}

const OneOfContainer = styled.div`
  margin-top: 24px;
`

const SchemaOneOf = (props: Props) => {
  const refs = props.oneOf?.map((o) => o?._ref).filter(Boolean)

  if (!refs?.length) return null

  return (
    <OneOfContainer>
      <PropertyIdentifier>
        <PropertyName>oneOf:</PropertyName>
        <FlexLine>
          <PropertyCaption>
            <ul>
              {refs.map((ref, index) => (
                <li key={index}>
                  <SchemaReferenceLink key={ref} schemaId={ref} removeMargin />
                </li>
              ))}
            </ul>
          </PropertyCaption>
        </FlexLine>
      </PropertyIdentifier>
    </OneOfContainer>
  )
}

export default SchemaOneOf
