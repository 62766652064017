import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import {
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema,
} from '../../templates/openapi/__generated__/OpenApiResponse'
import { getParameterExample, getParameterType } from '../../utils/parameter'
import {
  Header,
  PropertyIdentifier,
  FlexLine,
  PropertyItem,
  PropertyName,
  PropertyCaption,
  PropertyDescription,
  Value,
  PropertyTags,
  PropertyType,
} from '../properties-table/styled'
import SchemaReferenceLink from '../schema-reference-link'
import Tag from '../tag'
import { Container } from './styled'

type Props = {
  parameters:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters | null)[]
    | null
  childrenOpenApiParameter:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter | null)[]
    | null
  url: string

  objects:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema | null)[]
    | null
}

const Parameters = (props: Props) => {
  const parameters = [
    ...(props.parameters || []),
    ...(props.childrenOpenApiParameter || []),
  ]

  if (!parameters?.length) return null

  return (
    <Container>
      <Header>PARAMETERS</Header>
      {parameters.map((param) => {
        if (!param?.name || !param?.in) {
          return null
        }

        const { name, required, description, schema } = param

        const { type: parameterType, isArray } = getParameterType(
          param.schema,
          props.objects,
        )

        const example = getParameterExample(props.url, param, props.objects)

        const refList: string[] = []

        if (schema?._ref) {
          refList.push(schema._ref)
        }

        if (schema?.items?._ref) {
          refList.push(schema.items._ref)
        }

        if (schema && 'allOf' in schema && schema.allOf?.length) {
          const refs = schema.allOf
            .map((s) => s?._ref)
            .filter(Boolean) as string[]

          refList.push(...refs)
        }

        const enumValues = schema?.['enum']

        return (
          <PropertyItem key={`${name}_in_${param.in}`}>
            <PropertyIdentifier>
              <PropertyName>{name}</PropertyName>
              <FlexLine>
                <PropertyType>
                  {isArray ? `array (${parameterType})` : parameterType}
                </PropertyType>
              </FlexLine>
              <FlexLine>
                <PropertyCaption>{param.in}</PropertyCaption>
              </FlexLine>
              {required && (
                <PropertyTags>
                  <Tag variant="red">REQUIRED</Tag>
                </PropertyTags>
              )}
            </PropertyIdentifier>
            <PropertyDescription>
              <Value>
                {description && <MDXRenderer>{description}</MDXRenderer>}
              </Value>

              {refList.map((ref) => (
                <SchemaReferenceLink schemaId={ref} key={ref} />
              ))}

              {enumValues?.length && (
                <PropertyCaption>
                  Available values:
                  <ul>
                    {enumValues.map((o, index) => (
                      <li key={index}>{o}</li>
                    ))}
                  </ul>
                </PropertyCaption>
              )}
              {param.schema?._default !== null && (
                <PropertyCaption>
                  Default: {param.schema?._default}
                </PropertyCaption>
              )}
              {example && <PropertyCaption>Example: {example}</PropertyCaption>}
            </PropertyDescription>
          </PropertyItem>
        )
      })}
    </Container>
  )
}

export default Parameters

export const query = graphql`
  fragment fragment_ref_parameters on openApiSpecPath {
    childrenOpenApiParameter {
      id
      name
      in
      description
      example
      required
      style
      explode
      schema {
        _default
        type
        _ref
        enum
        items {
          _ref
          type
        }
      }
    }
  }

  fragment fragment_parameters on openApiSpecPath {
    ...fragment_ref_parameters
    parameters {
      name
      in
      description
      example
      required
      _ref
      style
      explode
      schema {
        _default
        type
        _ref
        enum
        allOf {
          _ref
        }
        items {
          _ref
          type
        }
      }
    }
  }
`
