import React from 'react'
import { AnchorTag } from './styled'

type Props = {
  content: string | null | undefined
}

const Anchor: React.FC<Props> = ({ content }) => {
  if (!content) {
    return null
  }
  const anchorId = content?.toLowerCase().replace(/\s/g, '-')
  return <AnchorTag className="anchor" id={anchorId} />
}

export default Anchor
