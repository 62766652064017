import styled from 'styled-components'
import { Body02, Subheader } from '../../styles/shared'

export const OneColumnTable = styled.table`
  margin-top: 15px;
  width: 100%;
  background: ${({ theme }) => theme.palette.gray20};
  border-collapse: collapse;
  color: ${({ theme }) => theme.palette.gray70};

  thead th {
    ${Subheader}
    text-align: left;
    text-transform: uppercase;
    padding: 8px 16px;
    border-bottom: 2px solid ${({ theme }) => theme.palette.gray40};
  }

  tr > td,
  tr > th {
    text-align: left;
  }

  tbody td {
    ${Body02}
    color: ${({ theme }) => theme.palette.gray80};
    padding: 8px 16px;
  }
`
