import React from 'react'
import { Container } from '../properties-table/styled'
import SchemaProperties from './schema-properties'
import SchemaItems from './schema-items'
import SchemaEnum from './schema-enum'
import SchemaAttributes from './schema-attributes'
import { SchemaProp } from './types'
import SchemaOneOf from './schema-one-of'

type Props = {
  schema: SchemaProp
  hideHeader?: boolean
}

const Schema: React.FC<Props> = ({ schema, hideHeader }) => {
  const { type, properties, items, required, additionalProperties } = schema

  const schemaAttributes: string[] = []

  if (typeof schema.pattern === 'string') {
    schemaAttributes.push(`Pattern: ${schema.pattern}`)
  }

  if (typeof schema.example === 'string') {
    schemaAttributes.push(`Example: ${schema.example}`)
  }

  return (
    <Container>
      <SchemaAttributes attributes={schemaAttributes} />

      <SchemaProperties
        properties={properties}
        additionalProperties={additionalProperties}
        required={required}
        hideHeader={hideHeader}
      />

      {type === 'array' && <SchemaItems items={items} />}

      {schema.enum && <SchemaEnum enum={schema.enum} />}

      {schema.oneOf && <SchemaOneOf oneOf={schema.oneOf} />}
    </Container>
  )
}

export default Schema
