import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import {
  Section,
  SectionTitle,
  SectionCaption,
  SectionWithoutBorder,
  SubSectionTitle,
} from '../../layouts/docs/styled'
import { OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema } from '../../templates/openapi/__generated__/OpenApiResponse'
import { getObjectAnchorId } from '../../utils/anchor'
import Anchor from '../anchor'
import Schema from '../schema'
import { SchemaProp } from '../schema/types'

type Props = {
  objects:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema | null)[]
    | null
}

const OpenApiTagObjects: React.FC<Props> = ({ objects }) => {
  if (!objects || objects.length < 1) {
    return null
  }

  return (
    <>
      <Anchor content="objects" />
      <SectionWithoutBorder>
        <SectionTitle>Objects</SectionTitle>
      </SectionWithoutBorder>

      {objects.map((schema) => {
        if (!schema) return null

        return (
          <Section key={schema.name} className="objects">
            <Anchor content={getObjectAnchorId(schema.id)} />
            <SubSectionTitle>
              <div>{schema.name}</div>
              <div>
                {schema.type && <SectionCaption>{schema.type}</SectionCaption>}
              </div>
            </SubSectionTitle>
            {schema.description && (
              <MDXRenderer>{schema.description}</MDXRenderer>
            )}
            <Schema schema={schema as SchemaProp} />
          </Section>
        )
      })}
    </>
  )
}

export const query = graphql`
  fragment fragment_childrenOpenApiSpecSchema on OpenApiSpecInfo {
    childrenOpenApiSpecSchema {
      id
      name
      type
      required
      description
      # enum
      _default
      pattern
      example
      additionalProperties {
        type
        description
        items {
          type
        }
      }
      oneOf {
        _ref
      }
      properties {
        name
        type
        format
        description
        readOnly
        writeOnly
        nullable
        enum
        pattern
        example
        required
        _ref
        minimum
        maximum
        maxLength
        minLength
        _default
        deprecated
        oneOf {
          _ref
        }
        additionalProperties {
          description
          type
        }
        properties {
          name
          type
          format
          description
          # enum
          _default
          _ref
          items {
            _ref
            name
            type
            required
            properties {
              name
              description
              type
              items {
                _ref
                name
              }
            }
          }
        }
        items {
          _ref
          anyOf {
            _ref
          }
          type
          name
          enum
          pattern
          oneOf {
            _ref
            type
          }

          properties {
            nullable
            name
            pattern
            type
            description
            minimum
            minLength
            minItems
            maximum
            maxLength
            items {
              _ref
              name
              type
              required
              properties {
                type
                pattern
                name
                minLength
                maxLength
                description
              }
            }
          }
        }
      }
      items {
        _ref
        name
      }
    }
  }
`

export default OpenApiTagObjects
