import React from 'react'
import { Header } from '../properties-table/styled'
import { SchemaPropertyItem } from './schema-property-item'
import { SchemaProp } from './types'
import AdditionalProperties from './additional-properties'
import Schema from '.'

type Props = Pick<SchemaProp, 'properties' | 'required'> & {
  hideHeader?: boolean
  additionalProperties?: SchemaProp['additionalProperties'] | null
}

const SchemaProperties: React.FC<Props> = ({
  properties,
  required,
  hideHeader,
  additionalProperties,
}) => {
  return (
    <>
      {!!properties?.length && (
        <>
          {!hideHeader && <Header>PROPERTIES</Header>}
          {properties.map((property) => (
            <SchemaPropertyItem
              key={`${property?.name || ''}-${property?.type || ''}`}
              property={property}
              required={required}
            >
              <Schema schema={{ ...property }} hideHeader={true} />
            </SchemaPropertyItem>
          ))}
        </>
      )}

      {additionalProperties && (
        <AdditionalProperties additionalProperties={additionalProperties} />
      )}
    </>
  )
}

export default SchemaProperties
