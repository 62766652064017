import React from 'react'
import Enum from '../enum'
import {
  PropertyCaption,
  PropertyDescription,
  PropertyIdentifier,
  PropertyItem,
  PropertyName,
} from '../properties-table/styled'

type Props = {
  enum: string[] | null
}

const SchemaEnum: React.FC<Props> = (props) => {
  return (
    <PropertyItem>
      <PropertyIdentifier>
        <PropertyName>enum</PropertyName>
        <PropertyCaption>(string)</PropertyCaption>
      </PropertyIdentifier>
      <PropertyDescription>
        <Enum enum={props.enum} />
      </PropertyDescription>
    </PropertyItem>
  )
}

export default SchemaEnum
