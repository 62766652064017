import React from 'react'
import { Value, PropertyDescription } from '../properties-table/styled'

type Props = {
  attributes: string[] | undefined
}

const SchemaAttributes: React.FC<Props> = ({ attributes }) => {
  if (!attributes) return null

  return (
    <>
      {attributes.map((attribute) => (
        <div key={attribute}>
          <PropertyDescription>
            <Value>{attribute}</Value>
          </PropertyDescription>
        </div>
      ))}
    </>
  )
}

export default SchemaAttributes
