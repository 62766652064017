import React from 'react'
import SchemaReferenceLink from '../schema-reference-link'
import { SchemaProp } from '../schema/types'
import { OneColumnTable } from '../table/styled'
import { Container } from './styled'

type Props = {
  title: string
  refs: SchemaProp['oneOf']
}

const SchemaReferenceOptions: React.FC<Props> = ({
  title = 'One of',
  refs,
}) => {
  if (!refs) return null

  return (
    <Container>
      <OneColumnTable>
        <thead>
          <tr>
            <th>{title}</th>
          </tr>
        </thead>
        <tbody>
          {refs.map((ref) => {
            if (ref?._ref) {
              return (
                <tr key={ref?._ref}>
                  <td>
                    <SchemaReferenceLink schemaId={ref?._ref} />
                  </td>
                </tr>
              )
            } else if (ref?.type) {
              return (
                <tr key={ref.type}>
                  <td>{ref.type}</td>
                </tr>
              )
            }
          })}
        </tbody>
      </OneColumnTable>
    </Container>
  )
}

export default SchemaReferenceOptions
