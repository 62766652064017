import React from 'react'
import {
  OpenApiResponse_allOpenApiSpecInfo_edges_node,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema,
} from '../../templates/openapi/__generated__/OpenApiResponse'
import Anchor from '../anchor'
import PathOperation from '../path-operation'

type Props = {
  tag: OpenApiResponse_allOpenApiSpecInfo_edges_node
  objects:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema | null)[]
    | null
}

const OpenApiTag: React.FC<Props> = ({ tag, objects }) => (
  <>
    {tag.childrenOpenApiSpecPath?.map((path, index) => (
      <div key={path?.id}>
        {index === 0 && (
          <h1>
            <Anchor content={path?.summary} />
            {tag.title}
          </h1>
        )}
        <div>
          <PathOperation path={path} index={index} objects={objects} />
        </div>
      </div>
    ))}
  </>
)

export default OpenApiTag
