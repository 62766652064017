import {
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter_schema,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters_schema,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema,
} from '../../templates/openapi/__generated__/OpenApiResponse'

export const getParameterExample = (
  url: string,
  parameter:
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter,
  objects:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema | null)[]
    | null,
) => {
  if (parameter.in === 'query') {
    return getQueryParameterExample(url, parameter, objects)
  }

  return ''
}

const getQueryParameterExample = (
  url: string,
  parameter:
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter,
  objects:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema | null)[]
    | null,
) => {
  const parameterName = parameter.name || ''

  const { type: parameterType, isArray } = getParameterType(
    parameter.schema,
    objects,
  )
  const isObject = parameterType === 'object'

  const exampleParameters = getParamExample(isArray, parameter.example)

  if (parameter.style === 'spaceDelimited') {
    if (!isArray || exampleParameters.length === 0) return ''

    const separator = parameter.explode ? `&${parameterName}=` : '%20'
    return formatParameter(
      url,
      `${parameterName}=${exampleParameters.join(separator)}`,
    )
  }

  if (parameter.style === 'pipeDelimited') {
    if (!isArray || exampleParameters.length === 0) return ''

    const separator = parameter.explode ? `&${parameterName}=` : '|'
    return formatParameter(
      url,
      `${parameterName}=${exampleParameters.join(separator)}`,
    )
  }

  if (parameter.style === 'deepObject') {
    if (!isObject || !parameter.explode) return ''

    const objectExample = getObjectExample(parameter)
    if (!objectExample) return ''

    return formatParameter(
      url,
      Object.keys(objectExample)
        .map((key) => {
          const value = objectExample[key]

          if (Array.isArray(value)) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return value.map((v) => `${parameterName}[${key}]=${v}`).join('&')
          } else {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${parameterName}[${key}]=${value}`
          }
        })
        .join('&'),
    )
  }

  // style === 'form'
  if (isObject) {
    const objectExample = getObjectExample(parameter)
    if (!objectExample) return ''

    if (parameter.explode === false) {
      return formatParameter(
        url,
        `${parameterName}=${Object.keys(objectExample)
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          .map((key) => `${key},${objectExample[key]}`)
          .join(',')}`,
      )
    }

    return formatParameter(
      url,
      Object.keys(objectExample)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .map((key) => `${key}=${objectExample[key]}`)
        .join('&'),
    )
  }

  if (exampleParameters.length === 0) return ''

  const separator = parameter.explode === false ? ',' : `&${parameterName}=`
  return formatParameter(
    url,
    `${parameterName}=${exampleParameters.join(separator)}`,
  )
}

const formatParameter = (url: string, parameter: string) =>
  `${url}?${parameter}`

const getParamExample = (
  isArray: boolean,
  example: string | null,
): unknown[] => {
  if (!example) {
    return []
  }

  if (!isArray) {
    return [example.replace(/"/g, '')]
  }

  try {
    const exampleArray = JSON.parse(example)

    if (Array.isArray(exampleArray)) {
      return exampleArray
    }

    return [example]
  } catch {
    return []
  }
}

const getObjectExample = (
  parameter:
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter,
): Record<string, unknown> | null => {
  try {
    const example = JSON.parse(parameter.example || 'null')
    return example && typeof example === 'object'
      ? (example as Record<string, unknown>)
      : null
  } catch {
    return null
  }
}

export const getParameterType = (
  parameterSchema:
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiParameter_schema
    | OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_parameters_schema
    | null,
  objects:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecSchema | null)[]
    | null,
) => {
  const ref = parameterSchema?._ref || parameterSchema?.items?._ref
  const isRef = !!ref
  const isRefArray = !!parameterSchema?.items?._ref
  const refSchema = objects?.find((x) => x?.id === ref)

  const isParameterArray = parameterSchema?.type === 'array'
  return {
    type:
      (isRef
        ? refSchema?.type
        : isParameterArray
        ? parameterSchema?.items?.type
        : parameterSchema?.type) || 'object',
    isArray: isRefArray || isParameterArray,
  }
}
