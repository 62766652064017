import React from 'react'
import { Helmet, HelmetProps } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type Props = {
  description?: string
  meta?: HelmetProps['meta']
  title?: string
}

type SiteMetadata = {
  site: {
    siteMetadata: {
      lang: string
      description: string
      title: string
      keywords: string[]
      author: string
    }
  }
}

const SEO: React.FC<Props> = (props) => {
  const { site } = useStaticQuery<SiteMetadata>(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            lang
            title
            description
            author
            keywords
          }
        }
      }
    `,
  )

  const { description, meta, title } = props

  const metaDescription = description || site.siteMetadata.description

  const { lang } = site.siteMetadata

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords.join(','),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...(meta || []),
      ]}
    />
  )
}

export default SEO
