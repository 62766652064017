import { graphql } from 'gatsby'
import React from 'react'

import {
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_responses,
  OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiSpecResponse,
} from '../../templates/openapi/__generated__/OpenApiResponse'
import SimpleTable from '../simple-table'
import { Container } from './styled'

type Props = {
  operationResponses:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_responses | null)[]
    | null
  specResponses:
    | (OpenApiResponse_allOpenApiSpecInfo_edges_node_childrenOpenApiSpecPath_childrenOpenApiSpecResponse | null)[]
    | null
    | undefined
}
const ResponseTable: React.FC<Props> = ({
  operationResponses,
  specResponses,
}) => {
  return (
    <Container>
      <SimpleTable>
        <thead>
          <tr>
            <th colSpan={2}>HTTP Status Code Summary</th>
          </tr>
        </thead>
        <tbody>
          {operationResponses?.map((item, _index) => {
            if (item) {
              const { statusCode, description, _ref } = item

              const descriptionFromRef =
                _ref && specResponses?.find((r) => r?.id === _ref)?.description

              return (
                <tr key={`${description || ''}${_index}`}>
                  <td>{statusCode}</td>
                  <td>{description || descriptionFromRef}</td>
                </tr>
              )
            }
          })}
        </tbody>
      </SimpleTable>
    </Container>
  )
}

export default ResponseTable

export const query = graphql`
  fragment fragment_responses on openApiSpecPath {
    responses {
      statusCode
      description
      _ref
      content {
        application_json {
          example
          schema {
            _ref
            type
            items {
              _ref
            }
            properties {
              items {
                items {
                  _ref
                }
              }
            }
          }
        }
      }
    }
  }

  fragment fragment_childrenOpenApiSpecResponse on openApiSpecPath {
    childrenOpenApiSpecResponse {
      id
      responseName
      statusCode
      description
      content {
        application_json {
          example
          schema {
            _ref
            items {
              _ref
            }
          }
        }
      }
    }
  }
`
